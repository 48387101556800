<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-26 17:07:37
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-10 19:36:06
-->
<template>
  <iframe :src="fileURL" class="iframe"></iframe>
</template>
<script>
export default {
  name: "pdf-view",
  data() {
    return {
      fileURL: this.$route.query.fileURL,
    };
  },
  mounted: function () {
    this.setUrl();
  },
  methods: {
    setUrl() {
      this.fileURL = "/pdfjs/web/viewer.html?file=" + this.fileURL;
    },
  },
};
</script>

<style lang="less" scoped>
.iframe {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
}
</style>


